import React from "react"

import SEO from "/components/seo"
import Jordan from "/components/Holiday2020/Jordan"

const JordanHoliday2020 = () => (
  <>
    <SEO
      title="Thesis | Holiday 2020: Jordan"
      description="Happy holidays from Thesis, a full-service digital agency in Portland, OR where strategy, creative, tech, and partner services combine to humanize the digital relationship between brands and people. Using data as the driver, our versatile work has the range to meet people where they are and the relevance to engage people as they are."
    />
    <Jordan />
  </>
)

export default JordanHoliday2020
